import React from 'react'
import './styles.css'

export default function index() {
  return (
    <>
      <div className="container">
        <h1>Lorem ipsum</h1>
      </div>
    </>
  )
}
